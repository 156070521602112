$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$color-green: #c4ccc9;
$color-deep-green: #0E3D2C;
$color-pink: #ddc4ce;
$color-pink-b: #f382b6;
$color-pink-d: #aa4a70;
$color-pink-l: #f1e7eb;
$color-purple: #AA4A70;
$color-pink-m: #f7eff3;
$color-pink-t: #f1e1e7;
$color-pink-f: #fefbfc;

$color-milk-71: #FDFBFBb5;

$font-primary: 'Karla';
$font-secondary: 'montserrat', sans-serif







