@import "variables";
@import "framework/framework";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min";
@import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.min";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	font-size: 16px;
	width: 100%;
	overflow-x: hidden;
}

body {
	background: $color-white;
	color: $color-deep-green;
	font-family: $font-primary;
	line-height: 1.5;
	width: 100%;
	overflow-x: hidden;
	position: relative;
}

a,
button {
	color: inherit;
	text-decoration: none;
	transition: .3s all;

	@include hover-focus {
		color: $color-purple;
	}
}

.social {
	display: flex;
	gap: rems(10);

	a {
		width: rems(24);
		height: rems(24);
		font-size: rems(14);
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: $color-purple;
		color: $color-white;
		text-decoration: none;

		@include hover-focus {
			background-color: $color-green;
		}
	}
}

#header {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	background-color: $color-milk-71;
	padding: 0;


	@include tablet-down {
		position: static;
	}

	.container {
		// max-width: rems(1800);
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.menu {
		display: flex;
		gap: rems(20);

		a {
			font-size: rems(20);
			text-transform: uppercase;
			text-decoration: none;
		}
	}

	@include tablet-down {
		.container {
			flex-direction: column;
			padding: rems(10) 0;
		}

		h1 {
			margin: 0;
		}

		.social {
			order: 3;
		}

		.menu {
			order: 2;
			margin: 1em auto;
		}
	}
}

#main {
	h2 {
		color: $color-deep-green;
		font-size: rems(24);
		text-transform: uppercase;
		font-weight: normal;
	}

	.btn {
		display: inline-block;
		border: none;
		text-transform: uppercase;
		padding: 1em;
		font-size: rems(15);
		color: $color-deep-green;
		background-color: $color-green;
		border: 1px solid $color-deep-green;

		@include hover-focus {
			background-color: $color-pink-m;
		}
	}

	a.block {
		display: block;
		padding: rems(70) 0;
		text-align: center;

		img {
			margin: 0 auto;
		}

		@include hover-focus {
			filter: brightness(0);
		}
	}

	#hero {
		img {
			object-fit: cover;
			object-position: center;
			width: 100%;
		}

		@media screen and (max-width: 600px) {
			img {
				min-height: 90vw;
			}
		}
	}

	#merch {
		padding: rems(50) 0 rems(120);
		background-image: linear-gradient(to bottom, $color-white 0%, $color-white 50%, $color-pink-l 50%, $color-pink-l 100%);

		h2 {
			text-align: center;
		}

		.wrap {
			display: flex;
			justify-content: space-around;

			@media screen and (max-width: 900px) {
				flex-direction: column;
				padding: rems(10) 0;
			}

			.card {
				display: block;
				margin: 0 rems(30);
				padding: rems(24);
				border-radius: 2px;
				border: 1px solid rgba(119, 19, 59, 0.25);
				background: $color-white;
				box-shadow: 0px 4px 12px 0px rgba(119, 19, 59, 0.25);
				text-align: center;
				flex: 1;

				@media screen and (max-width: 900px) {
					margin: rems(20) auto;
					width: 100%;
					max-width: rems(400);
				}

				.graphic {
					height: rems(260);
					display: flex;
					justify-content: center;
					align-items: center;
					border-bottom: 1px solid $color-pink;
				}

				p {
					text-transform: uppercase;
					font-size: rems(32);
				}
			}
		}
	}

	#music {
		padding-top: rems(100);

		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0;

			h2 {
				background-color: $color-pink-f;
				z-index: 10;
				font-size: rems(28);
				font-weight: 400;
				border: 1px solid $color-deep-green;
				margin: 0 0 rems(50);
				padding: rems(8) rems(60);
			}



			#music-carousel {

				// Item
				.music-item {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 100%;

					img {
						max-width: rems(460);

						@include tablet-down {
							max-width: rems(300);
						}
					}

					.album-title {
						font-size: rems(26);
						text-transform: uppercase;
					}

					.btn {
						padding: rems(8) rems(80);
						font-size: rems(24);
					}
				}

				.owl-stage-outer {}

				.owl-nav {

					button {
						color: $color-white;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);

						&.owl-prev {
							left: rems(15);
						}

						&.owl-next {
							right: rems(15);
						}

						.arrow { 
							i {
								font-size: rems(43);
								text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);


								@include hover-focus {
									transform: scale(1.1);
								}
							}
						}
					}
				}
			}
		}
	}

	#shows {
		padding-top: rems(50);

		.container {
			padding: rems(140) 0;
			border-top: 1px solid $color-black;
			border-bottom: 1px solid $color-black;
			display: flex;
			justify-content: space-around;
			align-items: center;

			@media screen and (max-width: 900px) {
				flex-direction: column-reverse;
				padding: rems(10) 0;

				.col {
					padding: rems(10);
				}
			}
		}

		h2 {
			font-size: rems(64);
			margin: 0;
			font-weight: bold;
		}

		.bar {
			align-self: stretch;
			border-left: 1px solid $color-pink;
		}

		.col {
			&:last-child {
				width: rems(344);
				max-width: 100%;
			}
		}

		p {
			font-size: rems(32);
			margin: 0;

			&:last-child {
				font-size: rems(24);
				border-bottom: 2px solid $color-pink;

				b {
					text-transform: uppercase;
				}
			}
		}
	}

	#tour {
		// padding-top: rems(50);

		.container {
			padding: rems(140) 0;
			// border-top: 1px solid $color-black;
			// border-bottom: 1px solid $color-black;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;

			@media screen and (max-width: 900px) {
				padding: rems(30) 0;
			}

			.wrapper {
				padding: rems(50) 0;
				background-color: $color-pink-m;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				h2 {
					font-size: rems(28);
					font-weight: 400;

					@include tablet-down {
						font-size: rems(22);
					}
				}

				#tour-dates {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					max-width: rems(1000);

					.event-group {
						display: flex;
						align-items: center;
						justify-content: center;
						gap: rems(16);
						margin: rems(24) 0;
						width: 100%;

						@include phone-down {
							flex-direction: column;
							margin-bottom: rems(40);
						}

						.event-info-group {
							border: 1px solid $color-deep-green;
							display: flex;
							flex: 3;
							background-color: $color-pink-t;
							padding: rems(8) rems(20);
							text-align: left;

							@include tablet-down {
								text-align: center;
								flex-direction: column;
							}

							.event-date {
								flex: 1;
								font-weight: 700;
							}

							.event-venue {
								flex: 1;
							}

							.event-location {
								flex: 1;
							}

						}

						.event-links {
							flex: 1;

							a.btn {
								background-color: $color-pink-t;
								width: 100%;
								padding: rems(8) 0;
								font-size: rems(16);
								border: 1px solid $color-deep-green;

								@include tablet-down {
									padding: rems(8) rems(40);
								}
							}
						}
					}
				}

				.btn {
					background-color: transparent;
					padding: 0;
					border: none;
					border-bottom: 1px solid $color-deep-green;
					font-size: rems(20);
				}
			}

		}

		h2 {
			font-size: rems(64);
			margin: 0;
			font-weight: bold;
		}

		.bar {
			align-self: stretch;
			border-left: 1px solid $color-pink;
		}

		.col {
			&:last-child {
				width: rems(344);
				max-width: 100%;
			}
		}

		p {
			font-size: rems(32);
			margin: 0;

			&:last-child {
				font-size: rems(24);
				border-bottom: 2px solid $color-pink;

				b {
					text-transform: uppercase;
				}
			}
		}
	}

	.box {
		display: inline-flex;
		width: rems(190);
		justify-content: space-between;

		span {
			font-size: rems(24);
			text-transform: uppercase;
			width: rems(160);
		}

	}

	#about {
		.container {
			padding: rems(70) 0;
			border-top: 1px solid $color-black;
			border-bottom: 1px solid $color-black;
			display: flex;
			justify-content: space-around;
			align-items: center;

			@media screen and (max-width: 900px) {
				flex-direction: column-reverse;
			}

			.content {
				width: rems(640);
				max-width: 100%;

				@media screen and (max-width: 900px) {
					padding: 0 rems(10) rems(30)
				}

				.social a {
					background-color: $color-purple;

					@include hover-focus {
						background-color: $color-green;
					}
				}
			}
		}

		.box {
			align-items: flex-end;
		}
	}

	#beauty {
		margin-bottom: rems(100);

		@include tablet-down {
			margin-bottom: none;
		}

		.container {
			padding: rems(60) 0 rems(70);
			border-bottom: 1px solid $color-black;
			display: flex;
			justify-content: space-around;
			align-items: center;

			@media screen and (max-width: 900px) {
				flex-direction: column;
			}
		}

		p {
			color: $color-pink-b;
			font-size: rems(32);
			text-transform: uppercase;

			@media screen and (max-width: 900px) {
				font-size: rems(26);
			}

		}

		.box img {
			transform: rotate(180deg);
		}
	}

	#subscribe {
		min-height: 100vh;
		padding: rems(90) 0 0;
		position: relative;

		img {
			object-position: top center;
			object-fit: cover;
			width: 100%;
		}

		.container {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			max-width: rems(1000);
			padding: rems(40) rems(80);
			background-color: $color-pink-f;
			border: 1px solid $color-deep-green;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;


			@include tablet-down {
				position: static;
				transform: translateY(rems(60));
			}

			h2 {
				margin-top: 0;
				text-align: center;
			}
		}

		form.newsletter {
			width: 100%;

			.inputs-wrap {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				input {
					padding: rems(10);
					width: 100%;
					border: 1px solid $color-pink-d;
					margin-bottom: rems(12);
					color: $color-black;
					background-color: $color-pink-t;

					&::placeholder {
						color: $color-black;
						text-transform: uppercase;
					}
				}
			}
		}

		.btn {
			padding: rems(4) rems(120);
			font-size: rems(20);
		}
	}
}

#footer {
	background-color: $color-pink-f;
	border-top: 2px solid $color-pink-d;
	padding: rems(80) 0 rems(100);

	@media screen and (max-width: 900px) {
		padding-bottom: 0;
	}

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media screen and (max-width: 900px) {
			flex-direction: column;
		}
	}

	.col {
		padding: rems(50);

		@media screen and (max-width: 900px) {
			padding: rems(30) rems(10);
			border-bottom: 1px solid $color-pink;
		}

		&:nth-child(2) {
			padding: rems(50) rems(70) rems(70);
			border-left: 1px solid $color-pink;
			border-right: 1px solid $color-pink;


			@media screen and (max-width: 900px) {
				order: 3;
				padding: rems(30) rems(10);
				border: none;
			}
		}

		b {
			text-transform: uppercase;
		}

		.badge {
			display: flex;
			padding: rems(10);
			background-color: transparent;
			border: 1px solid $color-pink-d;
			align-items: center;
			justify-content: center;
			width: rems(250);
			max-width: 100%;
			margin: 0 auto rems(30);
			font-weight: 700;

			span {
				font-size: rems(16);
				text-transform: uppercase;
				margin-left: rems(20);
				font-weight: 700;
			}
		}
	}

	.copyright {
		font-size: rems(12);
	}
}